/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/simple-jekyll-search@1.10.0/dest/simple-jekyll-search.min.js
 * - /npm/loading-attribute-polyfill@2.1.1/dist/loading-attribute-polyfill.umd.min.js
 * - /npm/dayjs@1.11.11/dayjs.min.js
 * - /npm/dayjs@1.11.11/locale/pt.min.js
 * - /npm/dayjs@1.11.11/plugin/relativeTime.min.js
 * - /npm/dayjs@1.11.11/plugin/localizedFormat.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
